import React, { useState, useEffect } from 'react';
import './Hero.css';
import mainLogo from '../img/home/logo.svg';

const winners = [
  {
    teamName: '3 WISE MEN',
    projectName: 'AgriGuard',
    link: 'https://devfolio.co/projects/agriguard-f64a',
    prize: '1st Place',
  },
  {
    teamName: 'LowLevelBrawlers',
    projectName: 'Adaptive Regenerative Braking',
    link: 'https://devfolio.co/projects/adaptive-regenerative-braking-91f8',
    prize: '2nd Place',
  },
  {
    teamName: 'Ripples',
    projectName: 'Ripples',
    link: 'https://devfolio.co/projects/ripples-413b',
    prize: '3rd Place',
  },
];

const Hero = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleDownload = (category) => {
    // Replace with actual file paths for each category
    const filePaths = {
      'Quantum Computing': 'Quantum Computing Scores.pdf',
      'EV and Future Mobility': 'EV and Future Mobility Scores.pdf',
      'GenAI and Machine Learning': 'GenAI and ML Scores.pdf',
      'Web3 and Blockchain': 'Web3 and Blockchain scores.pdf',
    };
    const filePath = filePaths[category];
    if (filePath) {
      const link = document.createElement('a');
      link.href = filePath;
      link.download = `${category}.pdf`;
      link.click();
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apply.devfolio.co/v2/sdk.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className="hero">
      <div className="hero-heading">
        <img src={mainLogo} alt="Quantamaze Logo" />
        <h2>A 36-Hour Hackathon</h2>
      </div>
      <div
        className="apply-button-container"
        style={{ height: '44px', width: '312px', marginTop: '1rem' }}
      >
        <div
          className="apply-button"
          data-hackathon-slug="quantamaze-2"
          data-button-theme="dark-inverted"
          style={{ height: '44px', width: '312px' }}
        ></div>
      </div>
      <div className="hero-subheading">
        <div className="event-date">November 14 - 16</div>
        <div className="event-venue">
          Nitte Meenakshi Institute of Technology, Bangalore
        </div>
      </div>

      <div className="winner-cards">
        {winners.map((winner, index) => (
          <div
            className={`winner-card winner-${index + 1}`}
            key={index}
            onClick={() => window.open(winner.link, '_blank')}
          >
            <div className="card-content">
              <i className="fa fa-trophy trophy-icon" aria-hidden="true"></i>
              <p className="winner-prize">{winner.prize}</p>
              <h3 className="winner-team-name">{winner.teamName}</h3>
              <p className="winner-project-name">{winner.projectName}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="team-scores-button-holder">
        <button onClick={toggleDropdown} className="team-scores-button">Team Scores</button>
        {dropdownVisible && (
          <div className="dropdown">
            <ul>
              <li onClick={() => handleDownload('Quantum Computing')}>Quantum Computing</li>
              <li onClick={() => handleDownload('EV and Future Mobility')}>EV and Future Mobility</li>
              <li onClick={() => handleDownload('GenAI and Machine Learning')}>GenAI and Machine Learning</li>
              <li onClick={() => handleDownload('Web3 and Blockchain')}>Web3 and Blockchain</li>
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default Hero;
